import { mapGetters } from 'vuex'
import { SvgLoader } from '@/components'

export default {
  components: {
    SvgLoader
  },
  computed: {
    ...mapGetters([
      'getterSelectedBot'
    ]),
    tooltipText () {
      if (this.$route.name === 'AccountConfiguration') {
        return this.$t('settings.accountConfiguration')
      }

      if (this.$route.name === 'BotConfiguration') {
        return this.$t('settings.botConfigurationScreen')
      }

      return ''
    }
  }
}
