var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "page-configuration internal-page"
  }, [_c('section', {
    staticClass: "internal-generic-header"
  }, [_c('div', {
    staticClass: "search-header"
  }, [_c('div', {
    staticClass: "container-title"
  }, [_c('h6', [_vm._v(" " + _vm._s(_vm.$t('settings.title')) + " " + _vm._s(_vm.$route.name === 'AccountConfiguration' ? _vm.$t('settings.account') : "Bot ".concat(_vm.getterSelectedBot.name)) + " ")])]), _c('div', {
    staticClass: "container-search"
  }, [_c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.auto",
      value: {
        content: _vm.tooltipText,
        offset: 10
      },
      expression: "{ content: tooltipText, offset: 10 }",
      modifiers: {
        "auto": true
      }
    }],
    staticClass: "btn-question"
  }, [_c('svgLoader', {
    attrs: {
      "src": "/img/icon-question.svg",
      "color": "#000"
    }
  })], 1)])])]), _c('section', {
    staticClass: "wrapper-configuration"
  }, [_c('router-view')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }